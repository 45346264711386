exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".ahlgy{width:100%;height:0}@media (max-width:1200px){.ahlgy{display:none}}._24gW9{display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;-ms-flex-pack:center;justify-content:center;-ms-flex-align:center;align-items:center;width:100%;height:300px;background-color:#fff}@media (max-width:1200px){._24gW9{display:none}}._1HcOD{width:100%;padding-top:10px}._1HcOD ._1YHA_{margin:0}._1HcOD ._1YHA_ input{position:relative}._1HcOD ._1YHA_ label{position:relative;top:-2px;margin-left:5px}._2MtXw{width:100%;text-align:right;margin-bottom:5px}._2MtXw .ZLqBP{display:-ms-inline-flexbox;display:inline-flex;-ms-flex-pack:center;justify-content:center;-ms-flex-align:center;align-items:center;width:30px;height:30px!important;padding:0;margin:0;border:none;-webkit-box-shadow:none;box-shadow:none;outline:none;background:#0db5ff;color:#fff;font-size:20px;vertical-align:middle}._2ySjb{width:calc(100% - 50px);max-width:1000px}._3i1gf{display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;-ms-flex-pack:center;justify-content:center;-ms-flex-align:center;align-items:center;width:100%;height:calc(100vh - 50px);max-height:600px;background-color:#fff}", ""]);

// exports
exports.locals = {
	"teal": "#00dcb2",
	"grey": "#b4b4b4",
	"recommendationVisualizationWrapperEmpty": "ahlgy",
	"recommendationVisualizationWrapper": "_24gW9",
	"checkBoxWrapper": "_1HcOD",
	"ageFilter": "_1YHA_",
	"expandButtonWrapper": "_2MtXw",
	"expandButton": "ZLqBP",
	"recommendationVisualizationModal": "_2ySjb",
	"recommendationVisualizationModalBody": "_3i1gf"
};