/* eslint-disable jsx-a11y/label-has-for */
import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { withRouter } from 'react-router';
import withStyles from 'isomorphic-style-loader/lib/withStyles';

import { withLogin } from './withLogin';
import s from './Login.scss';
import danubeFishImg from '../../assets/fishy_blue_horizontal.png';

class Login extends React.Component {
  static propTypes = {
    login: PropTypes.func.isRequired,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
  };

  static renderErrorMessage(msg) {
    return <div className={s.formError}>{msg}</div>;
  }

  constructor(props) {
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);

    this.state = {
      loginErrors: [],
    };
  }

  async handleSubmit(values, { setSubmitting }) {
    const { login, history } = this.props;
    setSubmitting(true);
    const result = await login({
      username: values.username,
      password: values.password,
    });

    if (!result) {
      this.setState({ loginErrors: ['Invalid credentials.'] });
    } else {
      this.setState({ loginErrors: [] });
      history.push('/');
    }
    setSubmitting(false);
  }

  render() {
    const { loginErrors } = this.state;

    return (
      <div className={s.container}>
        <Formik
          initialValues={{ username: '', password: '' }}
          validate={values => {
            const errors = {};
            if (!values.username) {
              errors.username = 'Required';
            }
            if (!values.password) {
              errors.password = 'Required';
            }
            return errors;
          }}
          onSubmit={this.handleSubmit}
        >
          {({ isSubmitting }) => (
            <Form className={s.form}>
              <div className={s.formContent}>
                <h1>Recruiting Demo</h1>
                <img src={danubeFishImg} alt="danube.ai" />
                <fieldset>
                  <div className={s.formGroup}>
                    <label htmlFor="username">User</label>
                    <Field type="text" id="username" name="username" />
                    <ErrorMessage
                      name="username"
                      render={Login.renderErrorMessage}
                    />
                  </div>
                  <div className={s.formGroup}>
                    <label htmlFor="password">Password</label>
                    <Field type="password" id="password" name="password" />
                    <ErrorMessage
                      name="password"
                      render={Login.renderErrorMessage}
                    />
                  </div>
                </fieldset>
                {loginErrors.length > 0 && (
                  <ul>
                    {loginErrors.map(error => (
                      <li key={error}>{error}</li>
                    ))}
                  </ul>
                )}
                <button
                  type="submit"
                  className={s.submitButton}
                  disabled={isSubmitting}
                >
                  Login
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    );
  }
}

export default withRouter(withLogin(withStyles(s)(Login)));
