/* eslint-disable react/sort-comp */
import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import ReactAutocomplete from 'react-autocomplete';

import s from './AutoCompleteField.scss';
import professionGroupCodesMap from '../../../constants/professionGroupCodesMap.json';

class AutoCompleteField extends React.Component {
  static propTypes = {
    autoCompleteValue: PropTypes.string,
    setAutoCompleteValue: PropTypes.func.isRequired,
  };

  static defaultProps = {
    autoCompleteValue: '',
  };

  constructor(props) {
    super(props);

    this.searchOptions = Object.keys(professionGroupCodesMap)
      .reduce((optionsList, code) => {
        professionGroupCodesMap[code].forEach(desciption => {
          optionsList.push({
            code,
            desciption,
          });
        });
        return optionsList;
      }, [])
      .sort((option1, option2) => {
        if (option1.desciption < option2.desciption) {
          return -1;
        }
        if (option1.desciption > option2.desciption) {
          return 1;
        }
        return 0;
      });
  }

  render() {
    const { autoCompleteValue, setAutoCompleteValue } = this.props;

    return (
      <ReactAutocomplete
        wrapperStyle={{ display: 'block', flex: 1 }}
        items={this.searchOptions}
        getItemValue={item => item.desciption}
        shouldItemRender={(item, value) =>
          item.desciption.toLowerCase().indexOf(value.toLowerCase()) > -1
        }
        renderItem={(item, highlighted) => (
          <div
            key={item.desciption}
            className={s.menuItem}
            style={{
              backgroundColor: highlighted ? '#e5f7fe' : 'transparent',
            }}
          >
            {item.desciption}
          </div>
        )}
        renderInput={props => <input className={s.searchInput} {...props} />}
        renderMenu={(items, value, style) => (
          <div
            className={s.menu}
            style={{
              ...style,
            }}
          >
            {items}
          </div>
        )}
        value={autoCompleteValue}
        onChange={e => {
          setAutoCompleteValue(e.target.value);
        }}
        onSelect={value => {
          setAutoCompleteValue(value);
        }}
      />
    );
  }
}

export default withStyles(s)(AutoCompleteField);
