exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3KgBc{width:100%;padding:10px 15px;border:2px solid #0db5ff;border-radius:5px 0 0 5px;font-size:17px;color:#00293e;text-align:center;outline:none;-webkit-box-shadow:none;box-shadow:none}._3KgBc ._3KgBc:hover{border:2px solid #66d1ff}.c7WtI{position:fixed;max-height:30vh;margin-top:5px;z-index:1000;border:none;border-radius:10px;overflow:auto;background:#fff;text-align:center;-webkit-box-shadow:0 0 5px 0 rgba(0,0,0,.1);box-shadow:0 0 5px 0 rgba(0,0,0,.1)}.GhWyC{padding:10px 15px;font-size:17px}.GhWyC:hover{cursor:pointer}", ""]);

// exports
exports.locals = {
	"teal": "#00dcb2",
	"grey": "#b4b4b4",
	"searchInput": "_3KgBc",
	"menu": "c7WtI",
	"menuItem": "GhWyC"
};