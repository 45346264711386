export const SET_RUNTIME_VARIABLE = 'SET_RUNTIME_VARIABLE';
export const SET_LOCALE_START = 'SET_LOCALE_START';
export const SET_LOCALE_SUCCESS = 'SET_LOCALE_SUCCESS';
export const SET_LOCALE_ERROR = 'SET_LOCALE_ERROR';

export const SET_LICENSE_STATE = 'SET_LICENSE_STATE';

export const WORKFIELD_PREFIX = 'workField_';

export const SEARCH_TERM_TYPE = 'searchTerm';
export const CANDIDATE_TYPE = 'candidate';

export const RECOMMENDATION_RESULTS_HISTORY_LENGTH = 3;

export const SET_RECOMMENDATION_RESULT = 'SET_RECOMMENDATION_RESULT';

export const SET_VISUALIZATION_SETTINGS = 'SET_VISUALIZATION_SETTINGS';
