exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._38eX5 .-_r9T{padding:10px;background-color:#fff;border-radius:5px;border:1px solid #0db5ffff;opacity:.9}._38eX5 .-_r9T .CnL4V{margin:0}._38eX5 .-_r9T .yMQBn{margin-bottom:0}", ""]);

// exports
exports.locals = {
	"teal": "#00dcb2",
	"grey": "#b4b4b4",
	"container": "_38eX5",
	"customTooltip": "-_r9T",
	"bins": "CnL4V",
	"tooltipLine": "yMQBn"
};