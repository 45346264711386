// eslint-disable-next-line import/prefer-default-export
export const calculateDanubeScore = (
  matches,
  columnKeys,
  columnScores,
  ignoredColumns = [],
) =>
  columnKeys.reduce((scoreSum, columnKey, index) => {
    if (!ignoredColumns.includes(columnKey)) {
      return scoreSum + (matches[index] || 0) * columnScores[index];
    }
    return scoreSum;
  }, 0);
