import React from 'react';

import CandidateSearchPage from 'components/CandidateSearchPage';

class CandidateSearchPageWrapper extends React.Component {
  render() {
    return <CandidateSearchPage />;
  }
}

export default CandidateSearchPageWrapper;
