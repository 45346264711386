exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3iY75{width:100%;text-align:center;margin-top:10vh;padding:40px 20px 20px;font-size:25px;color:#00293e}@media (max-width:1000px){._3iY75{padding:40px 10px 20px}}._3iY75 h1{margin:50px 0 20px;font-size:40px}", ""]);

// exports
exports.locals = {
	"teal": "#00dcb2",
	"grey": "#b4b4b4",
	"landingPageContainer": "_3iY75"
};