import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import runtime from './runtime';
import intl from './intl';
import recommendationResults from './recommendationResults';
import visualizationSettings from './visualizationSettings';

export default combineReducers({
  runtime,
  intl,
  form: formReducer,
  recommendationResults,
  visualizationSettings,
});
