exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3fsRc{width:100%;min-height:100%}", ""]);

// exports
exports.locals = {
	"teal": "#00dcb2",
	"grey": "#b4b4b4",
	"searchPageContainer": "_3fsRc"
};