/* eslint-disable react/sort-comp */
import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { Form, Modal } from 'react-bootstrap';
import { compose } from 'react-apollo';
import { connect } from 'react-redux';
import FaExpand from 'react-icons/lib/fa/arrows-alt';

import s from './RecommendationResultsVisualization.scss';
import DistributionGraph from '../DistributionGraph';
import { setVisualizationSettings as setVisualizationSettingsAction } from '../../actions/visualizationSettings';

class RecommendationResultsVisualization extends React.Component {
  static contextTypes = {
    client: PropTypes.object.isRequired,
  };

  static propTypes = {
    recommendationResults: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
    visualizationSettings: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    setVisualizationSettings: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      showModal: false,
    };

    this.onIncludeAgeCheck = this.onIncludeAgeCheck.bind(this);
  }

  onIncludeAgeCheck(evt) {
    this.props.setVisualizationSettings({
      newVisualizationSettings: {
        includeAge: evt.target.checked,
      },
    });
  }

  render() {
    const { recommendationResults, visualizationSettings } = this.props;
    const { showModal } = this.state;

    if (recommendationResults.length === 0) {
      return <div className={s.recommendationVisualizationWrapperEmpty} />;
    }

    const datasets = recommendationResults.map(recommendationResult => ({
      label: recommendationResult.label,
      minValue: visualizationSettings.includeAge
        ? recommendationResult.minDanubeScore
        : recommendationResult.minDanubeScoreWithoutAge,
      maxValue: visualizationSettings.includeAge
        ? recommendationResult.maxDanubeScore
        : recommendationResult.maxDanubeScoreWithoutAge,
      values: visualizationSettings.includeAge
        ? recommendationResult.danubeScores
        : recommendationResult.danubeScoresWithoutAge,
      referenceValue: visualizationSettings.includeAge
        ? recommendationResult.referenceCandidate.danubeScore
        : recommendationResult.referenceCandidate.danubeScoreWithoutAge,
      referenceLabel: recommendationResult.referenceCandidate.name,
    }));

    const content = [
      <DistributionGraph
        key="graph"
        datasets={datasets}
        typeOfChart="Line" // only implemented for 'Line'
      />,
      <div key="form" className={s.checkBoxWrapper}>
        <Form.Group className={s.ageFilter}>
          <Form.Check
            id="manufacturer-filter"
            type="checkbox"
            label="Include age"
            checked={this.state.includeAge}
            onChange={this.onIncludeAgeCheck}
          />
        </Form.Group>
      </div>,
    ];

    return [
      <div key="expandButton" className={s.expandButtonWrapper}>
        <button
          className={s.expandButton}
          onClick={() => {
            this.setState({ showModal: true });
          }}
        >
          <FaExpand />
        </button>
      </div>,
      <div key="visWrapper" className={s.recommendationVisualizationWrapper}>
        {content}
      </div>,
      <Modal
        key="modal"
        show={showModal}
        onHide={() => {
          this.setState({ showModal: false });
        }}
        dialogClassName={s.recommendationVisualizationModal}
        centered
      >
        <Modal.Body className={s.recommendationVisualizationModalBody}>
          {content}
        </Modal.Body>
      </Modal>,
    ];
  }
}

const mapStateToProps = store => ({
  recommendationResults: store.recommendationResults,
  visualizationSettings: store.visualizationSettings,
});

const mapDispatchToProps = dispatch => ({
  setVisualizationSettings: payload =>
    dispatch(setVisualizationSettingsAction(payload)),
});

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withStyles(s),
)(RecommendationResultsVisualization);
