/* eslint-disable react/sort-comp */
import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { compose } from 'react-apollo';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import gql from 'graphql-tag';

import s from './CandidateRecommendations.scss';
import AuthenticationWrapper from '../Auth/AuthenticationWrapper';
import Error from '../Error';
import CandidateRecommendationsPage from './CandidateRecommendationsPage';

const getMeanCandidateForSearchTermQuery = gql`
  query getMeanCandidateForSearchTerm($searchTerm: String!) {
    getMeanCandidateForSearchTerm(searchTerm: $searchTerm) {
      meanCV
      formattedMeanCV
      formattedReferenceCVs
    }
  }
`;

class RecommendationsBySearchTerm extends React.Component {
  static contextTypes = {
    client: PropTypes.object.isRequired,
  };

  static propTypes = {
    searchTerm: PropTypes.string.isRequired,
    onCandidateClick: PropTypes.func,
    onRecommendationResult: PropTypes.func,
  };

  static defaultProps = {
    onCandidateClick: () => {},
    onRecommendationResult: () => {},
  };

  constructor(props) {
    super(props);

    this.state = {
      loadingMeanReferenceCV: true,
      meanReferenceCV: null,
      errors: [],
    };

    this.getMeanReferenceCV = this.getMeanReferenceCV.bind(this);
  }

  componentDidMount() {
    this.getMeanReferenceCV();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.searchTerm !== this.props.searchTerm) {
      this.getMeanReferenceCV();
    }
  }

  async getMeanReferenceCV() {
    this.setState({
      loadingMeanReferenceCV: true,
    });

    const result = await this.context.client.query({
      query: getMeanCandidateForSearchTermQuery,
      variables: {
        searchTerm: this.props.searchTerm,
      },
    });

    if (result?.data?.errors) {
      this.setState({
        loadingMeanReferenceCV: false,
        meanReferenceCV: {},
        errors: [...(result.data.errors || [])],
      });
      return;
    }

    if (result?.data?.getMeanCandidateForSearchTerm) {
      this.setState({
        loadingMeanReferenceCV: false,
        meanReferenceCV: {
          cv: result.data.getMeanCandidateForSearchTerm.meanCV,
          formattedCV:
            result.data.getMeanCandidateForSearchTerm.formattedMeanCV,
          formattedReferenceCVs:
            result.data.getMeanCandidateForSearchTerm.formattedReferenceCVs,
        },
        errors: [],
      });
    } else {
      this.setState({
        loadingMeanReferenceCV: false,
        meanReferenceCV: {},
        errors: ['Unkown server error'],
      });
    }
  }

  render() {
    const { searchTerm, onCandidateClick, onRecommendationResult } = this.props;

    // eslint-disable-next-line no-unused-vars
    const { loadingMeanReferenceCV, meanReferenceCV, errors } = this.state;

    if (errors && errors.length > 0) {
      return (
        <div className={s.candidateRecommendationsContainer}>
          {errors.length > 0 && <Error errors={errors} />}
        </div>
      );
    }

    return (
      <div className={s.candidateRecommendationsContainer}>
        {meanReferenceCV && (
          <CandidateRecommendationsPage
            referenceCV={meanReferenceCV}
            onCandidateClick={onCandidateClick}
            onRecommendationResult={onRecommendationResult}
            isMean
            searchTerm={searchTerm}
          />
        )}
      </div>
    );
  }
}

export default compose(
  AuthenticationWrapper,
  withRouter,
  withStyles(s),
)(RecommendationsBySearchTerm);
