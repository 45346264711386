// eslint-disable-next-line import/prefer-default-export
export const renderStarsLabel = (percentage, starsCount = 5) => {
  // eslint-disable-next-line prettier/prettier
  const numberFullStars = Math.max(1, Math.min(5, Math.floor((percentage / 100) * starsCount) + 1));

  let starsLabel = '';

  for (let i = 0; i < numberFullStars; i += 1) {
    starsLabel = `${starsLabel}\u2605`;
  }
  for (let i = numberFullStars; i < starsCount; i += 1) {
    starsLabel = `${starsLabel}\u2606`;
  }

  return starsLabel;
};
