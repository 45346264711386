/* eslint-disable react/sort-comp */
import React from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import FaUser from 'react-icons/lib/fa/user';
import FaInfo from 'react-icons/lib/fa/info-circle';
import FaCaretDown from 'react-icons/lib/fa/caret-down';
import FaCaretUp from 'react-icons/lib/fa/caret-up';
import { Accordion, Card } from 'react-bootstrap';

import s from './CandidateRecommendationsPage.scss';
import CandidateComparisionTable from '../CandidateComparisionTable';
import Error from '../../Error';
import professionGroupCodesMap from '../../../constants/professionGroupCodesMap.json';
import computerSkillCodesMap from '../../../constants/computerSkillCodesMap.json';
import languageSkillCodesMap from '../../../constants/languageSkillCodesMap.json';
import softSkillCodesMap from '../../../constants/softSkillCodesMap.json';
import otherSkillCodesMap from '../../../constants/otherSkillCodesMap.json';
import internationalDegreeCodesMap from '../../../constants/internationalDegreeCodesMap.json';
import {
  WORKFIELD_PREFIX,
  SEARCH_TERM_TYPE,
  CANDIDATE_TYPE,
} from '../../../constants';
import { calculateDanubeScore } from '../../../util/danube';

export const labelMap = {
  age: 'Age',
  totalExperienceYears: 'Years of exp.',
  highestDegreeCode: 'Highest education',
  workFields: 'Work fields',
  computerSkills: 'Computer skills',
  languageSkills: 'Language skills',
  softSkills: 'Soft skills',
  otherSkills: 'Other skills',
};

const recommendCandidatesQuery = gql`
  query recommendCandidates(
    $referenceCV: String!
    $columnBoost: ColumnBoost
    $relevantWorkFields: [String]
  ) {
    recommendCandidates(
      referenceCV: $referenceCV
      columnBoost: $columnBoost
      relevantWorkFields: $relevantWorkFields
    ) {
      referenceCandidate
      candidates {
        candidate
        betterIn
        worseIn
      }
      columnKeys
      columnScores
      rules
    }
  }
`;

const formatCandidateData = candidateData => {
  if (candidateData.highestDegreeCode) {
    // eslint-disable-next-line no-param-reassign
    candidateData.highestDegreeCode =
      internationalDegreeCodesMap[candidateData.highestDegreeCode] || '-';
  }
  if (candidateData.workFields) {
    // eslint-disable-next-line no-param-reassign
    candidateData.workFields = candidateData.workFields
      .map(workField => professionGroupCodesMap[workField][0] || workField)
      .sort();
  }
  if (candidateData.computerSkills) {
    // eslint-disable-next-line no-param-reassign
    candidateData.computerSkills = candidateData.computerSkills
      .map(skill => computerSkillCodesMap[skill][0] || skill)
      .sort();
  }
  if (candidateData.languageSkills) {
    // eslint-disable-next-line no-param-reassign
    candidateData.languageSkills = candidateData.languageSkills
      .map(skill => languageSkillCodesMap[skill][0] || skill)
      .sort();
  }
  if (candidateData.softSkills) {
    // eslint-disable-next-line no-param-reassign
    candidateData.softSkills = candidateData.softSkills
      .map(skill => softSkillCodesMap[skill][0] || skill)
      .sort();
  }
  if (candidateData.otherSkills) {
    // eslint-disable-next-line no-param-reassign
    candidateData.otherSkills = candidateData.otherSkills
      .map(skill => otherSkillCodesMap[skill][0] || skill)
      .sort();
  }
  return candidateData;
};

class CandidateRecommendationsPage extends React.Component {
  static contextTypes = {
    client: PropTypes.object.isRequired,
  };

  static propTypes = {
    referenceCV: PropTypes.shape({
      cv: PropTypes.string,
      formattedCV: PropTypes.string,
      formattedReferenceCVs: PropTypes.string,
    }).isRequired,
    onCandidateClick: PropTypes.func,
    onRecommendationResult: PropTypes.func,
    isMean: PropTypes.bool,
    searchTerm: PropTypes.string,
  };

  static defaultProps = {
    onCandidateClick: () => {},
    onRecommendationResult: () => {},
    isMean: false,
    searchTerm: null,
  };

  constructor(props) {
    super(props);

    this.getDefaultState = this.getDefaultState.bind(this);

    this.state = this.getDefaultState();

    this.getRecommendedCandidates = this.getRecommendedCandidates.bind(this);
    this.handleAttributeClick = this.handleAttributeClick.bind(this);
    this.renderReferenceCandidate = this.renderReferenceCandidate.bind(this);
  }

  componentDidMount() {
    this.getRecommendedCandidates();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.referenceCV !== this.props.referenceCV) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState(this.getDefaultState(), () => {
        this.getRecommendedCandidates();
      });
    }
  }

  getDefaultState() {
    const referenceCandidate = JSON.parse(
      this.props.referenceCV.formattedCV || '{}',
    );

    const referenceCandidates = JSON.parse(
      this.props.referenceCV.formattedReferenceCVs || '[]',
    );

    const columnBoost = {
      columns: [],
    };
    // Note(andreas.roschal) - autoboost disabled for now
    /*
    if (this.props.isMean) {
      const workFieldCode = Object.keys(professionGroupCodesMap).find(
        key => professionGroupCodesMap[key][0] === this.props.searchTerm,
      );
      if (workFieldCode != null) {
        columnBoost.columns.push(`${WORKFIELD_PREFIX}${workFieldCode}`);
      }
    }
    */

    return {
      loadingRecommendedCandidates: true,
      recommendedCandidates: [],
      referenceCandidate: {
        candidate: formatCandidateData(referenceCandidate),
      },
      referenceCandidates,
      columnKeys: [],
      columnScores: [],
      rules: [],
      errors: [],
      columnBoost,
      referenceCandidateExpanded: this?.state?.referenceCandidateExpanded || false, // eslint-disable-line prettier/prettier
      infoExpanded: this?.state?.infoExpanded || false,
    };
  }

  async getRecommendedCandidates() {
    const { onRecommendationResult, isMean, searchTerm } = this.props;

    this.setState({
      loadingRecommendedCandidates: true,
    });

    const result = await this.context.client.query({
      query: recommendCandidatesQuery,
      variables: {
        referenceCV: this.props.referenceCV.cv,
        columnBoost: this.state.columnBoost,
        relevantWorkFields: searchTerm != null ? [searchTerm] : [],
      },
    });

    if (result?.data?.errors) {
      this.setState({
        loadingRecommendedCandidates: false,
        recommendedCandidates: [],
        columnKeys: [],
        columnScores: [],
        rules: [],
        errors: [...(result.data.errors || [])],
      });
      return;
    }

    if (result?.data?.recommendCandidates) {
      const formattedRecommendedCandidates = (
        result.data.recommendCandidates.candidates || []
      ).map(c => ({
        ...c,
        candidate: formatCandidateData(JSON.parse(c.candidate || '{}')),
      }));
      const columnKeys = result.data.recommendCandidates.columnKeys || [];
      const columnScores = result.data.recommendCandidates.columnScores || [];
      const rules = JSON.parse(result.data.recommendCandidates.rules || '[]');

      formattedRecommendedCandidates.forEach(recommendedCandidate => {
        // eslint-disable-next-line no-param-reassign
        recommendedCandidate.candidate.danubeScoreWithoutAge = calculateDanubeScore(
          recommendedCandidate.candidate.matches,
          columnKeys,
          columnScores,
          ['age'],
        );
      });

      const formattedReferenceCandidate = {
        candidate: formatCandidateData(
          JSON.parse(result.data.recommendCandidates.referenceCandidate || '{}')
            .candidate,
        ),
      };
      formattedReferenceCandidate.candidate.danubeScoreWithoutAge = calculateDanubeScore(
        formattedReferenceCandidate.candidate.matches,
        columnKeys,
        columnScores,
        ['age'],
      );

      this.setState(
        {
          loadingRecommendedCandidates: false,
          referenceCandidate: formattedReferenceCandidate,
          recommendedCandidates: formattedRecommendedCandidates,
          columnKeys,
          columnScores,
          rules,
          errors: [],
        },
        () => {
          const { referenceCandidate, recommendedCandidates } = this.state;

          let candidateName = [
            referenceCandidate?.candidate?.firstName,
            referenceCandidate?.candidate?.middleName,
            referenceCandidate?.candidate?.lastName,
          ]
            .filter(n => n != null && n !== '')
            .join(' ');

          if (candidateName === '') candidateName = 'Ideal Candidate';

          const recommendationVisualizationData = {
            type: isMean ? SEARCH_TERM_TYPE : CANDIDATE_TYPE,
            boosted: this.state.columnBoost.columns.length > 0,
            searchTerm,
            minDanubeScore: 0,
            minDanubeScoreWithoutAge: 0,
            maxDanubeScore: columnScores.reduce(
              (scoreSum, score) => scoreSum + score,
              0,
            ),
            maxDanubeScoreWithoutAge: columnScores.reduce(
              (scoreSum, columnScore, index) => {
                if (columnKeys[index] !== 'age') {
                  return scoreSum + columnScore;
                }
                return scoreSum;
              },
              0,
            ),
            referenceCandidate: {
              name: candidateName,
              danubeScore: referenceCandidate.candidate.danubeScore,
              danubeScoreWithoutAge:
                referenceCandidate.candidate.danubeScoreWithoutAge,
            },
            danubeScores: recommendedCandidates.map(
              c => c.candidate.danubeScore,
            ),
            danubeScoresWithoutAge: recommendedCandidates.map(
              c => c.candidate.danubeScoreWithoutAge,
            ),
          };

          onRecommendationResult(recommendationVisualizationData);
        },
      );
    } else {
      this.setState({
        loadingRecommendedCandidates: false,
        recommendedCandidates: [],
        columnKeys: [],
        columnScores: [],
        rules: [],
        errors: ['Unkown server error'],
      });
    }
  }

  handleAttributeClick({ attribute }) {
    if (this.state.loadingRecommendedCandidates) {
      return;
    }

    const { columnBoost } = this.state;
    const index = columnBoost.columns.indexOf(attribute);
    if (index !== -1) {
      columnBoost.columns.splice(index, 1);
    } else {
      columnBoost.columns.push(attribute);
    }
    this.setState({ columnBoost }, () => {
      this.getRecommendedCandidates();
    });
  }

  renderReferenceCandidate() {
    const { isMean, searchTerm } = this.props;
    const { referenceCandidate, referenceCandidates } = this.state;

    let candidateName = [
      referenceCandidate?.candidate?.firstName,
      referenceCandidate?.candidate?.middleName,
      referenceCandidate?.candidate?.lastName,
    ]
      .filter(n => n != null && n !== '')
      .join(' ');

    if (candidateName === '') candidateName = 'Ideal Candidate';

    const candidateWorkFields = Object.keys(
      referenceCandidate.candidate,
    ).reduce((allWorkFields, property) => {
      if (property.startsWith(WORKFIELD_PREFIX)) {
        // eslint-disable-next-line no-param-reassign
        allWorkFields[
          professionGroupCodesMap[
            property.substring(WORKFIELD_PREFIX.length)
          ][0]
        ] = referenceCandidate.candidate[property];
      }
      return allWorkFields;
    }, {});

    return (
      <div className={s.referenceCandidateContainer}>
        <div className={s.profileContainer}>
          <div className={s.imageContainer}>
            <FaUser />
          </div>
          <div className={s.nameContainer}>{candidateName}</div>
          {isMean && (
            <div className={s.isMeanInfo}>
              <div className={s.infoIcon}>
                <FaInfo />
              </div>
              <div className={s.infoText}>
                This is an ideal candidate created from the following{' '}
                <b>{referenceCandidates.length}</b> candidates matching your
                search term &quot;<b>{searchTerm}</b>&quot;:
                <ul>
                  {referenceCandidates.map(c => {
                    const cName = [c?.firstName, c?.middleName, c?.lastName]
                      .filter(n => n != null && n !== '')
                      .join(' ');
                    return (
                      <li key={c.id}>
                        <a href={`/recommendations/candidate/${c.id}`}>
                          <div>
                            <b>{cName}</b>
                          </div>
                        </a>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          )}
        </div>
        <div className={s.propertiesContainer}>
          {Object.keys(labelMap).map((property, index) => {
            const propName = labelMap[property];
            let value = referenceCandidate.candidate[property];

            if (property === 'workFields') {
              value = (
                <ul>
                  {value.map(v => (
                    <li key={v}>
                      {candidateWorkFields[v]} years exp. in <i>{v}</i>
                    </li>
                  ))}
                </ul>
              );
            } else if (Array.isArray(value)) {
              value = (
                <ul>
                  {value.map(v => (
                    <li key={v}>{v}</li>
                  ))}
                </ul>
              );
            }

            return (
              <div
                key={property}
                className={
                  index % 2 === 0 ? s.oddPropertyLine : s.evenPropertyLine
                }
              >
                <div className={s.propTag}>{propName}</div>
                <div className={s.valueTag}>{value}</div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }

  render() {
    const { onCandidateClick, isMean, searchTerm } = this.props;

    const {
      loadingRecommendedCandidates,
      recommendedCandidates,
      referenceCandidate,
      columnKeys,
      columnScores,
      rules,
      columnBoost,
      errors,
      referenceCandidateExpanded,
      infoExpanded,
    } = this.state;

    const candidateName = [
      referenceCandidate?.candidate?.firstName,
      referenceCandidate?.candidate?.middleName,
      referenceCandidate?.candidate?.lastName,
    ]
      .filter(n => n != null && n !== '')
      .join(' ');

    if (errors && errors.length > 0) {
      return (
        <div className={s.candidateRecommendationsPage}>
          {errors.length > 0 && <Error errors={errors} />}
        </div>
      );
    }

    return (
      <div className={s.candidateRecommendationsPage}>
        <Accordion
          onSelect={eventKey => {
            this.setState({
              infoExpanded: eventKey === 'info',
            });
          }}
        >
          <Card className={s.card}>
            <Card.Header className={s.cardHeader}>
              <Accordion.Toggle variant="link" eventKey="info">
                {infoExpanded ? <FaCaretUp /> : <FaCaretDown />}
                <FaInfo /> Info
              </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey="info">
              <Card.Body className={s.cardBody}>
                <div className={s.infoContainer}>
                  <div className={s.infoInnerContainer}>
                    {searchTerm !== null && (
                      <div>
                        <p>
                          You are searching suitable candidates for{' '}
                          <b>{searchTerm}</b>.
                        </p>
                        {isMean && (
                          <p>
                            The reference candidate is a virtual ideal candidate
                            created from all candidates having experience in
                            this work field.
                          </p>
                        )}
                        {!isMean && (
                          <p>
                            The reference candidate is <b>{candidateName}</b>.
                          </p>
                        )}
                      </div>
                    )}
                    <p>
                      Listed candidates have similar or better qualifications
                      than the reference candidate.
                    </p>
                    <span className={s.infoText}>
                      Click on a percentage-tag to weight this property higher!
                    </span>
                  </div>
                </div>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
        <Accordion
          onSelect={eventKey => {
            this.setState({
              referenceCandidateExpanded: eventKey === 'refCandidate',
            });
          }}
        >
          <Card className={s.card}>
            <Card.Header className={s.cardHeader}>
              <Accordion.Toggle variant="link" eventKey="refCandidate">
                {referenceCandidateExpanded ? <FaCaretUp /> : <FaCaretDown />}
                <FaUser /> Reference candidate
              </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey="refCandidate">
              <Card.Body className={s.cardBody}>
                {this.renderReferenceCandidate()}
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
        <div className={s.candidateTableContainer}>
          <CandidateComparisionTable
            columnKeys={columnKeys}
            columnScores={columnScores}
            referenceCandidate={referenceCandidate}
            loading={loadingRecommendedCandidates}
            upsellingCandidates={recommendedCandidates}
            onAttributeClick={this.handleAttributeClick}
            columnBoost={columnBoost}
            rules={rules}
            labelMap={labelMap}
            onCandidateClick={onCandidateClick}
          />
        </div>
      </div>
    );
  }
}

export default withStyles(s)(CandidateRecommendationsPage);
