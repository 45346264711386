import React from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';

import s from './LandingPage.scss';
import AuthenticationWrapper from '../Auth/AuthenticationWrapper';

class LandingPage extends React.Component {
  render() {
    return (
      <div className={s.landingPageContainer}>
        <h1>Find your ideal candidate</h1>
        <span>Type in a workfield in the search box at the top.</span>
      </div>
    );
  }
}

export default AuthenticationWrapper(withStyles(s)(LandingPage));
