import {
  SEARCH_TERM_TYPE,
  CANDIDATE_TYPE,
  RECOMMENDATION_RESULTS_HISTORY_LENGTH,
  SET_RECOMMENDATION_RESULT,
} from '../constants';

const initialState = [];

export default function recommendationResults(
  state = [...initialState],
  action,
) {
  switch (action.type) {
    case SET_RECOMMENDATION_RESULT: {
      const { recommendationResult } = action.payload;

      // create label
      let label = '';
      if (recommendationResult.type === SEARCH_TERM_TYPE) {
        label = recommendationResult.searchTerm;
      } else if (recommendationResult.type === CANDIDATE_TYPE) {
        label =
          recommendationResult.searchTerm != null
            ? recommendationResult.searchTerm
            : recommendationResult.referenceCandidate.name;
      }

      const labeledRecommendationResult = {
        ...recommendationResult,
        label,
      };

      let newState = [...state];
      newState.push(labeledRecommendationResult);

      if (newState.length > RECOMMENDATION_RESULTS_HISTORY_LENGTH) {
        newState = newState.slice(
          newState.length - RECOMMENDATION_RESULTS_HISTORY_LENGTH,
        );
      }

      return newState;
    }
    default:
      return state;
  }
}
