import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import {
  LineChart,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Line,
  ResponsiveContainer,
  CartesianGrid,
  ReferenceLine,
  ReferenceDot,
} from 'recharts';
import computeHistogram from 'compute-histogram';

// eslint-disable-next-line css-modules/no-unused-class
import s from './DistributionGraph.scss';
import { renderStarsLabel } from '../../util/stars';

const BIN_COUNT = 51;

const lineStyles = [
  { stroke: '#0db5ffff', strokeWidth: 4, refStrokeWidth: 2 },
  { stroke: '#aaaaaa60', strokeWidth: 2, refStrokeWidth: 1.5 },
  { stroke: '#aaaaaa30', strokeWidth: 1, refStrokeWidth: 1 },
];

const highlightedStyle = {
  stroke: '#0db5ffff',
  strokeWidth: 4,
  refStrokeWidth: 2,
};

const disabledStyle = {
  stroke: '#aaaaaa30',
  strokeWidth: 1,
  refStrokeWidth: 1,
};

class DistributionGraph extends React.Component {
  static propTypes = {
    datasets: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        minValue: PropTypes.number.isRequired,
        maxValue: PropTypes.number.isRequired,
        values: PropTypes.arrayOf(PropTypes.number),
        referenceValue: PropTypes.number.isRequired,
        referenceLabel: PropTypes.string.isRequired,
      }),
    ).isRequired,
    typeOfChart: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      visData: [],
      linesData: [],
      referenceLinesData: [],
      quintilePercentages: [],
      highlightedKey: null,
    };

    this.renderLineChart = this.renderLineChart.bind(this);
    this.renderTooltip = this.renderTooltip.bind(this);
    this.prepareData = this.prepareData.bind(this);
  }

  componentDidMount() {
    this.prepareData();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.datasets !== this.props.datasets ||
      prevProps.typeOfChart !== this.props.typeOfChart ||
      prevState.highlightedKey !== this.state.highlightedKey
    ) {
      this.prepareData();
    }
  }

  prepareData() {
    const { datasets } = this.props;

    // step 1: build the data object
    const data = [];

    for (let i = 0; i < BIN_COUNT; i += 1) {
      const item = {};
      const percentage = (i * 100) / (BIN_COUNT - 1);
      item.percentage = percentage;
      item.starGroup = Math.max(
        0,
        Math.min(5, Math.floor((percentage / 100) * 6)),
      );
      item.bin = i + 1;
      data.push(item);
    }

    /* eslint-disable prettier/prettier */
    data[Math.ceil(data.length * 0.1) - 1].stars = renderStarsLabel(10);
    data[Math.ceil(data.length * 0.3) - 1].stars = renderStarsLabel(30);
    data[Math.ceil(data.length * 0.5) - 1].stars = renderStarsLabel(50);
    data[Math.ceil(data.length * 0.7) - 1].stars = renderStarsLabel(70);
    data[Math.ceil(data.length * 0.9) - 1].stars = renderStarsLabel(90);
    /* eslint-enable prettier/prettier */

    const quintilePercentages = [];

    const datasetLabelsAndKeys = datasets.map((dataset, i) => ({
      key: `(${i + 1}) ${dataset.label}`,
      label: dataset.label,
    }));

    datasets.forEach((dataset, dsIndex) => {
      // step 2: calculate histogram values and remove minmax afterwards
      const minMaxValues = [...dataset.values];
      minMaxValues.unshift(dataset.minValue); // add min value
      minMaxValues.push(dataset.maxValue); // add max value

      const histogramValues = computeHistogram(minMaxValues, BIN_COUNT);
      histogramValues[0][1] -= 1; // remove minValue form array
      histogramValues[histogramValues.length - 1][1] -= 1; // remove maxValue form array

      quintilePercentages.push({
        0: 0,
        1: 0,
        2: 0,
        3: 0,
        4: 0,
      });

      // step 3: map over histogramValues and add labels
      histogramValues.forEach((bin, i) => {
        const normalisedValue = Math.round(
          (bin[1] * 100) / dataset.values.length,
        ); // normalize y-axis
        data[i][datasetLabelsAndKeys[dsIndex].key] = normalisedValue; // add labels

        const quintile = Math.max(0, Math.min(4, Math.floor((i / (BIN_COUNT - 1)) * 5))); // eslint-disable-line prettier/prettier
        quintilePercentages[dsIndex][quintile] += normalisedValue;
      });
    });

    // step 4: make Lines
    const linesData = datasetLabelsAndKeys.map((labelAndKey, i) => {
      let stroke;
      let strokeWidth;

      if (this.state.highlightedKey == null) {
        stroke = lineStyles[datasets.length - i - 1].stroke;
        strokeWidth = lineStyles[datasets.length - i - 1].strokeWidth;
      } else {
        stroke =
          this.state.highlightedKey === labelAndKey.key
            ? highlightedStyle.stroke
            : disabledStyle.stroke;
        strokeWidth =
          this.state.highlightedKey === labelAndKey.key
            ? highlightedStyle.strokeWidth
            : disabledStyle.strokeWidth;
      }

      return {
        key: labelAndKey.key,
        type: 'monotone',
        dataKey: labelAndKey.key,
        name: labelAndKey.label,
        stroke,
        strokeWidth,
        dot: false,
      };
    });

    // step 3.5: make the Reference lines
    const referenceLinesData = datasets.map((dataset, dsIndex) => {
      const normalizedRefValue =
        (dataset.referenceValue - dataset.minValue) / dataset.maxValue; // maps value in [0, 1]
      const refBinNumber = Math.floor(normalizedRefValue * BIN_COUNT); // maps value from [0, 1] to [0, (binSize - 1)]
      const refPercentage = (refBinNumber * 100) / (BIN_COUNT - 1);

      let label = '';

      if (this.state.highlightedKey == null) {
        // render label only for the last dataset
        if (dsIndex === datasets.length - 1) {
          label = datasets[dsIndex].referenceLabel;
        }
      } else if (
        this.state.highlightedKey === datasetLabelsAndKeys[dsIndex].key
      ) {
        label = datasets[dsIndex].referenceLabel;
      }

      let stroke;
      let refStrokeWidth;

      if (this.state.highlightedKey == null) {
        stroke = lineStyles[datasets.length - dsIndex - 1].stroke;
        refStrokeWidth =
          lineStyles[datasets.length - dsIndex - 1].refStrokeWidth;
      } else {
        stroke =
          this.state.highlightedKey === datasetLabelsAndKeys[dsIndex].key
            ? highlightedStyle.stroke
            : disabledStyle.stroke;
        refStrokeWidth =
          this.state.highlightedKey === datasetLabelsAndKeys[dsIndex].key
            ? highlightedStyle.refStrokeWidth
            : disabledStyle.refStrokeWidth;
      }

      return {
        referenceLine: {
          x: refPercentage,
          stroke,
          strokeWidth: refStrokeWidth,
          label: {
            position: 'top',
            value: label,
          },
        },
        referenceDot: {
          x: refPercentage,
          y: data[refBinNumber][datasetLabelsAndKeys[dsIndex].key],
          r: 5,
          fill: stroke,
          stroke,
        },
      };
    });

    this.setState({
      visData: data,
      linesData,
      referenceLinesData,
      quintilePercentages,
    });
  }

  renderTooltip({ active, payload }) {
    const { quintilePercentages } = this.state;

    if (active && payload && payload.length) {
      const payloadValues = payload.map((e, i) => {
        const quintile = Math.max(0, Math.min(4, Math.floor((payload[0].payload.percentage / 100) * 5))); // eslint-disable-line prettier/prettier
        return (
          <p
            key={`line-${i}`} // eslint-disable-line react/no-array-index-key
            style={{ color: `${lineStyles[payload.length - 1 - i].stroke}` }}
            className={s.tooltipLine}
          >
            {`${e.name} : ${quintilePercentages[i][quintile]}%`}
          </p>
        );
      });
      return (
        <div className={s.customTooltip}>
          <p className={s.bins}>{`${renderStarsLabel(
            payload[0].payload.percentage,
          )}`}</p>
          {payloadValues}
        </div>
      );
    }
    return null;
  }

  renderLineChart() {
    const { visData, linesData, referenceLinesData } = this.state;

    const lines = linesData.map(lineData => <Line {...lineData} />);

    // step 3.5: make the Reference lines
    const referenceLines = referenceLinesData.map(referenceLineData => [
      <ReferenceLine {...referenceLineData.referenceLine} />,
      <ReferenceDot {...referenceLineData.referenceDot} />,
    ]);

    return (
      <ResponsiveContainer height="100%" width="100%" className={s.container}>
        <LineChart data={visData} margin={{ top: 20 }}>
          <XAxis xAxisId="0" dataKey="percentage" unit="%" hide />
          <XAxis
            xAxisId="1"
            dataKey="starGroup"
            allowDuplicatedCategory={false}
            tickSize={10}
            height={10}
            tickFormatter={() => ''}
          />
          <XAxis
            xAxisId="2"
            dataKey="stars"
            interval={0}
            axisLine={false}
            tickLine={false}
            tickSize={0}
            tick={{ letterSpacing: '-2px' }}
            height={20}
          />
          <YAxis type="number" unit="%" domain={[0, 'dataMax']} hide />
          <Legend
            onMouseEnter={state => {
              this.setState({ highlightedKey: state.dataKey });
            }}
            onMouseLeave={() => {
              this.setState({ highlightedKey: null });
            }}
          />
          <Tooltip content={this.renderTooltip} />
          <CartesianGrid stroke="#eee" strokeDasharray="3 3" />
          {referenceLines}
          {lines}
        </LineChart>
      </ResponsiveContainer>
    );
  }

  render() {
    const { typeOfChart } = this.props;

    return <>{typeOfChart === 'Line' && this.renderLineChart()}</>;
  }
}

export default withStyles(s)(DistributionGraph);
