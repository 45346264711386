import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import withStyles from 'isomorphic-style-loader/lib/withStyles';

import s from './TagsMap.scss';
import messages from './messages';
import { labelMap } from '../CandidateRecommendations/CandidateRecommendationsPage';

export const danubeTagsMap = {
  recommendationScore: messages.recommendationScore,
};

const TagsMap = props => {
  const { columnKeys, columnScores } = props;

  // normalize column scores
  const total = columnScores.reduce((sum, score) => sum + score, 0);
  const normalizedColumnScores = columnScores.map(
    score => (score / total) * 100,
  );

  // get max column score
  const maxColumnScore = Math.max(...normalizedColumnScores);

  return normalizedColumnScores.length > 0 ? (
    <div className={s.tagsMap}>
      {normalizedColumnScores.map((score, index) => (
        <div
          key={`normalizedColumnScore-${index}`} // eslint-disable-line react/no-array-index-key
          className={
            score === maxColumnScore ? s.danubeWrapper : s.danubeWrapperLight
          }
          title={`Score: ${columnScores[index]}, Normalized Score: ${score}`}
        >
          <span className={s.label}>
            {danubeTagsMap[columnKeys[index]] ? (
              <FormattedMessage {...danubeTagsMap[columnKeys[index]]} />
            ) : (
              labelMap[columnKeys[index]]
            )}
            :&nbsp;
            {Math.round(score)}%
          </span>
        </div>
      ))}
    </div>
  ) : null;
};

TagsMap.propTypes = {
  columnKeys: PropTypes.arrayOf(PropTypes.string).isRequired,
  columnScores: PropTypes.arrayOf(PropTypes.number).isRequired,
};

export default withStyles(s)(TagsMap);
