exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2ftTC{display:-ms-flexbox;display:flex;-ms-flex-pack:center;justify-content:center;-ms-flex-align:center;align-items:center;width:100%;max-width:600px;border-radius:5px}._2ftTC ._1Ft0p{padding:5px 20px;color:#fff;font-size:27px;border:2px solid #0db5ff;border:none;border-radius:0 5px 5px 0;background:#0db5ff;cursor:pointer;outline:none;-webkit-box-shadow:none;box-shadow:none}._2ftTC ._1Ft0p:hover{background:#66d1ff}", ""]);

// exports
exports.locals = {
	"teal": "#00dcb2",
	"grey": "#b4b4b4",
	"searchField": "_2ftTC",
	"searchButton": "_1Ft0p"
};