/* eslint-disable react/sort-comp */
import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { compose } from 'react-apollo';
import { connect } from 'react-redux';

import s from './CandidateSearchPage.scss';
import AuthenticationWrapper from '../Auth/AuthenticationWrapper';
import RecommendationsByCandidateId from '../CandidateRecommendations/RecommendationsByCandidateId';
import RecommendationsBySearchTerm from '../CandidateRecommendations/RecommendationsBySearchTerm';
import { setRecommencationResult as setRecommencationResultAction } from '../../actions/recommendationResults';
import { SEARCH_TERM_TYPE } from '../../constants';

class CandidateSearchPage extends React.Component {
  static contextTypes = {
    client: PropTypes.object.isRequired,
  };

  static propTypes = {
    location: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    history: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    setRecommencationResult: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    let mostRecentSearchTerm = null;

    if (props.location.search != null) {
      if (props.location.search.startsWith('?term=')) {
        mostRecentSearchTerm = decodeURIComponent(
          props.location.search.substring('?term='.length),
        );
      } else if (props.location.search.startsWith('?candidate=')) {
        const searchTermIndex = props.location.search.indexOf('&term=');

        if (searchTermIndex !== -1) {
          mostRecentSearchTerm = decodeURIComponent(
            props.location.search.substring(searchTermIndex + '?term='.length),
          );
        }
      }
    }

    this.state = {
      mostRecentSearchTerm,
    };

    this.onCandidateClick = this.onCandidateClick.bind(this);
    this.onRecommendationResult = this.onRecommendationResult.bind(this);
  }

  onCandidateClick(candidateId) {
    const { history } = this.props;
    const { mostRecentSearchTerm } = this.state;
    if (mostRecentSearchTerm != null) {
      history.push(
        `/search?candidate=${encodeURIComponent(
          candidateId,
        )}&term=${encodeURIComponent(mostRecentSearchTerm)}`,
      );
    } else {
      history.push(`/search?candidate=${encodeURIComponent(candidateId)}`);
    }
  }

  onRecommendationResult(recommendationResult) {
    let { mostRecentSearchTerm } = this.state;

    if (recommendationResult.type === SEARCH_TERM_TYPE) {
      mostRecentSearchTerm = recommendationResult.searchTerm;
    }

    this.props.setRecommencationResult({ recommendationResult });

    this.setState({ mostRecentSearchTerm });
  }

  render() {
    let listComponent = null;

    if (this.props.location.search != null) {
      if (this.props.location.search.startsWith('?term=')) {
        const searchTermUrl = decodeURIComponent(
          this.props.location.search.substring('?term='.length),
        );
        listComponent = (
          <RecommendationsBySearchTerm
            searchTerm={searchTermUrl}
            onCandidateClick={this.onCandidateClick}
            onRecommendationResult={this.onRecommendationResult}
          />
        );
      } else if (this.props.location.search.startsWith('?candidate=')) {
        const searchTermIndex = this.props.location.search.indexOf('&term=');
        const candidateIdUrl =
          searchTermIndex !== -1
            ? decodeURIComponent(
                this.props.location.search.substring(
                  '?candidate='.length,
                  searchTermIndex,
                ),
              )
            : decodeURIComponent(
                this.props.location.search.substring('?candidate='.length),
              );
        const searchTermUrl =
          searchTermIndex !== -1
            ? decodeURIComponent(
                this.props.location.search.substring(
                  searchTermIndex + '?term='.length,
                ),
              )
            : null;
        listComponent = (
          <RecommendationsByCandidateId
            candidateId={candidateIdUrl}
            searchTerm={searchTermUrl}
            onCandidateClick={this.onCandidateClick}
            onRecommendationResult={this.onRecommendationResult}
          />
        );
      }
    }

    return <div className={s.searchPageContainer}>{listComponent}</div>;
  }
}

const mapDispatchToProps = dispatch => ({
  setRecommencationResult: payload =>
    dispatch(setRecommencationResultAction(payload)),
});

export default compose(
  AuthenticationWrapper,
  connect(
    null,
    mapDispatchToProps,
  ),
  withStyles(s),
)(CandidateSearchPage);
