/* eslint-disable react/sort-comp */
import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import FaPlusSquare from 'react-icons/lib/fa/plus-square';
import FaMinusSqare from 'react-icons/lib/fa/minus-square';
import FaArrowAltCircleUp from 'react-icons/lib/fa/arrow-circle-up';
import FaUser from 'react-icons/lib/fa/user';
import { compose } from 'react-apollo';
import { connect } from 'react-redux';

import s from './CandidateComparisionTable.scss';
import Spinner from '../../Spinner';
import { danubeTagsMap } from '../../DanubeTags';
import professionGroupCodesMap from '../../../constants/professionGroupCodesMap.json';
import { WORKFIELD_PREFIX } from '../../../constants';
import { renderStarsLabel } from '../../../util/stars';

const excludedKeys = [];

class CandidateComparisionTable extends React.Component {
  static propTypes = {
    columnKeys: PropTypes.arrayOf(PropTypes.string).isRequired,
    columnScores: PropTypes.arrayOf(PropTypes.number).isRequired,
    loading: PropTypes.bool.isRequired,
    referenceCandidate: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    upsellingCandidates: PropTypes.arrayOf(PropTypes.object),
    onAttributeClick: PropTypes.func.isRequired,
    columnBoost: PropTypes.shape({
      columns: PropTypes.arrayOf(PropTypes.string),
    }),
    rules: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
    labelMap: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    onCandidateClick: PropTypes.func,
    visualizationSettings: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  };

  static defaultProps = {
    upsellingCandidates: [],
    columnBoost: {
      columns: [],
    },
    onCandidateClick: () => {},
  };

  constructor(props) {
    super(props);

    this.renderPropertiesColumn = this.renderPropertiesColumn.bind(this);
    this.renderCandidateColumn = this.renderCandidateColumn.bind(this);
    this.renderUpsellingCandidateColumns = this.renderUpsellingCandidateColumns.bind(this); // eslint-disable-line prettier/prettier
  }

  // eslint-disable-next-line class-methods-use-this
  renderPropertiesColumn(sortedKeys) {
    const {
      columnKeys,
      columnScores,
      rules,
      columnBoost,
      loading,
      labelMap,
      visualizationSettings,
    } = this.props;
    const filteredColumnScores = columnScores.filter(
      (score, index) => excludedKeys.indexOf(columnKeys[index]) === -1,
    );

    // normalize column scores
    const total = filteredColumnScores.reduce((sum, score) => sum + score, 0);
    const normalizedColumnScores = filteredColumnScores.map(
      score => (score / total) * 100,
    );

    return (
      <div className={s.headersRow}>
        <div className={s.candidateCell} />
        <div className={s.starsCell} />
        {sortedKeys.map((key, index) => {
          const sIndex = columnKeys.findIndex(cKey => cKey === key);

          const rule = rules.find(r => r.property === key);
          const isArray =
            rule.type === 'OVERLAP_LOCAL' ||
            rule.type === 'OVERLAP_GLOBAL' ||
            rule.type === 'OVERLAP_SELECTION';

          let label = key;

          if (label.startsWith(WORKFIELD_PREFIX)) {
            label = (
              <span>
                Exp. in{' '}
                <i>
                  {
                    professionGroupCodesMap[
                      label.substring(WORKFIELD_PREFIX.length)
                    ][0]
                  }
                </i>
              </span>
            );
          } else {
            label = labelMap[key] || key;
          }

          return (
            <div
              key={key}
              /* eslint-disable prettier/prettier */
              className={`${
                s.cell} ${
                index % 2 === 0 ? s.oddCell : s.evenCell} ${
                s.attributeCell} ${
                isArray ? s.arrayCell : ''} ${
                key === 'age' && !visualizationSettings.includeAge ? s.inactiveSell : ''
              }`}
              /* eslint-enable prettier/prettier */
            >
              <button
                onClick={() => {
                  this.props.onAttributeClick({ attribute: key });
                }}
                disabled={loading}
              >
                {sIndex >= 0 ? (
                  <div className={s.importanceTag}>
                    {Math.round(normalizedColumnScores[sIndex])}%
                  </div>
                ) : (
                  ''
                )}
                {danubeTagsMap[key] ? (
                  <FormattedMessage {...danubeTagsMap[key]} />
                ) : (
                  <span>{label}</span>
                )}
                {columnBoost.columns.indexOf(key) !== -1 && (
                  <FaArrowAltCircleUp />
                )}
              </button>
            </div>
          );
        })}
      </div>
    );
  }

  // eslint-disable-next-line class-methods-use-this
  renderCandidateColumn(
    sortedKeys,
    candidate,
    highestDanubeScore,
    highestDanubeScoreWithoutAge,
    columnKeys,
    columnScores,
    place,
    isReference = false,
  ) {
    const { rules, onCandidateClick, visualizationSettings } = this.props;

    let candidateName = [
      candidate?.candidate?.firstName,
      candidate?.candidate?.middleName,
      candidate?.candidate?.lastName,
    ]
      .filter(n => n != null && n !== '')
      .join(' ');

    if (candidateName === '' && isReference) {
      candidateName = 'Ideal Candidate';
    }

    let starsPercentage;

    if (visualizationSettings.includeAge) {
      starsPercentage =
        (candidate.candidate.danubeScore / highestDanubeScore) * 100;
    } else {
      starsPercentage =
        (candidate.candidate.danubeScoreWithoutAge /
          highestDanubeScoreWithoutAge) *
        100;
    }

    return (
      <div key={candidate?.candidate?.id} className={s.candidateRow}>
        <div className={`${s.candidateCell} ${isReference ? s.selected : ''}`}>
          {isReference ? (
            <span className={s.noLink}>
              <FaUser />
              <div>{candidateName}</div>
              <div>(reference)</div>
            </span>
          ) : (
            <button
              className={s.link}
              onClick={() => {
                onCandidateClick(candidate?.candidate?.id);
              }}
            >
              <FaUser />
              <div>{candidateName}</div>
              <div>({place}.)</div>
            </button>
          )}
        </div>
        <div className={`${s.starsCell} ${isReference ? s.selected : ''}`}>
          {renderStarsLabel(starsPercentage)}
        </div>
        {sortedKeys.map((key, index) => {
          let isBetter = false;
          let isWorse = false;

          const rule = rules.find(r => r.property === key);
          const inverse = rule && rule.type.startsWith('INVERSE');

          if (!isReference) {
            isBetter = candidate.betterIn.includes(key);
            isWorse = candidate.worseIn.includes(key);
          }

          let icon = <div className={s.compareIcon} />;
          if (isBetter) {
            if (inverse) {
              icon = (
                <FaMinusSqare className={`${s.compareIcon} ${s.better}`} />
              );
            } else {
              icon = (
                <FaPlusSquare className={`${s.compareIcon} ${s.better}`} />
              );
            }
          } else if (isWorse) {
            if (inverse) {
              icon = <FaPlusSquare className={`${s.compareIcon} ${s.worse}`} />;
            } else {
              icon = <FaMinusSqare className={`${s.compareIcon} ${s.worse}`} />;
            }
          }

          const value = candidate.candidate[key];
          const isArray =
            rule.type === 'OVERLAP_LOCAL' ||
            rule.type === 'OVERLAP_GLOBAL' ||
            rule.type === 'OVERLAP_SELECTION';

          return (
            <div
              key={key}
              /* eslint-disable prettier/prettier */
              className={`${
                s.cell} ${
                index % 2 === 0 ? s.oddCell : s.evenCell} ${
                isReference ? s.selected : ''} ${
                isArray ? s.arrayCell : ''} ${
                key === 'age' && !visualizationSettings.includeAge ? s.inactiveSell : ''
              }`}
              /* eslint-enable prettier/prettier */
            >
              {icon}
              {isArray ? (
                <ul>
                  {value != null
                    ? value.map((v, i) => <li key={`li-${i}`}>{v}</li>) // eslint-disable-line react/no-array-index-key
                    : '-'}
                </ul>
              ) : (
                <span>{value}</span>
              )}
            </div>
          );
        })}
      </div>
    );
  }

  // eslint-disable-next-line class-methods-use-this
  renderUpsellingCandidateColumns(
    sortedKeys,
    highestDanubeScore,
    highestDanubeScoreWithoutAge,
    columnKeys,
    columnScores,
  ) {
    const { loading, upsellingCandidates, visualizationSettings } = this.props;

    let sortedUpsellingCandidates;

    if (visualizationSettings.includeAge) {
      sortedUpsellingCandidates = upsellingCandidates;
    } else {
      sortedUpsellingCandidates = [...upsellingCandidates];
      sortedUpsellingCandidates.sort((c1, c2) => {
        if (
          c1.candidate.danubeScoreWithoutAge <
          c2.candidate.danubeScoreWithoutAge
        ) {
          return 1;
        }
        if (
          c1.candidate.danubeScoreWithoutAge >
          c2.candidate.danubeScoreWithoutAge
        ) {
          return -1;
        }
        return 0;
      });
    }

    if (loading) {
      return (
        <div className={s.loadingUpsellingCandidatesContainer}>
          <Spinner show margin="0" fontSize="4px" />
        </div>
      );
    }

    return (
      <div className={s.upsellingCandidatesContainer}>
        {sortedUpsellingCandidates.map((candidate, i) =>
          this.renderCandidateColumn(
            sortedKeys,
            candidate,
            highestDanubeScore,
            highestDanubeScoreWithoutAge,
            columnKeys,
            columnScores,
            i + 1,
          ),
        )}
      </div>
    );
  }

  render() {
    const { columnKeys, columnScores, referenceCandidate } = this.props;

    let sortedKeys = [];

    if (columnKeys && columnKeys.length > 0) {
      sortedKeys = columnKeys
        .map((key, index) => ({
          key,
          score: columnScores[index],
        }))
        .map(ks => ks.key)
        .filter(key => !excludedKeys.includes(key));
    }

    const highestDanubeScore =
      columnScores && columnScores.length > 0
        ? columnScores.reduce((scoreSum, score) => scoreSum + score, 0)
        : null;

    const highestDanubeScoreWithoutAge =
      columnScores && columnScores.length > 0
        ? columnScores.reduce(
            (scoreSum, score, index) =>
              columnKeys[index] !== 'age' ? scoreSum + score : scoreSum,
            0,
          )
        : null;

    return (
      <div className={s.candidateComparisionTable}>
        {this.renderPropertiesColumn(sortedKeys)}
        {this.renderCandidateColumn(
          sortedKeys,
          referenceCandidate,
          highestDanubeScore,
          highestDanubeScoreWithoutAge,
          columnKeys,
          columnScores,
          0,
          true,
        )}
        {this.renderUpsellingCandidateColumns(
          sortedKeys,
          highestDanubeScore,
          highestDanubeScoreWithoutAge,
          columnKeys,
          columnScores,
        )}
      </div>
    );
  }
}

const mapStateToProps = store => ({
  visualizationSettings: store.visualizationSettings,
});

export default compose(
  connect(mapStateToProps),
  withStyles(s),
)(CandidateComparisionTable);
