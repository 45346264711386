/* eslint-disable react/sort-comp */
import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { compose } from 'react-apollo';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import gql from 'graphql-tag';

import s from './CandidateRecommendations.scss';
import AuthenticationWrapper from '../Auth/AuthenticationWrapper';
import Error from '../Error';
import CandidateRecommendationsPage from './CandidateRecommendationsPage';

const getCandidateByIdQuery = gql`
  query getCandidateById($id: String!) {
    getCandidateById(id: $id) {
      CV
      formattedCV
    }
  }
`;

class RecommendationsById extends React.Component {
  static contextTypes = {
    client: PropTypes.object.isRequired,
  };

  static propTypes = {
    candidateId: PropTypes.string.isRequired,
    searchTerm: PropTypes.string,
    onCandidateClick: PropTypes.func,
    onRecommendationResult: PropTypes.func,
  };

  static defaultProps = {
    searchTerm: null,
    onCandidateClick: () => {},
    onRecommendationResult: () => {},
  };

  constructor(props) {
    super(props);

    this.state = {
      loadingCV: true,
      cv: null,
      errors: [],
    };

    this.getCV = this.getCV.bind(this);
  }

  componentDidMount() {
    this.getCV();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.candidateId !== this.props.candidateId) {
      this.getCV();
    }
  }

  async getCV() {
    this.setState({
      loadingCV: true,
    });

    const result = await this.context.client.query({
      query: getCandidateByIdQuery,
      variables: {
        id: this.props.candidateId,
      },
    });

    if (result?.data?.errors) {
      this.setState({
        loadingCV: false,
        cv: {},
        errors: [...(result.data.errors || [])],
      });
      return;
    }

    if (result?.data?.getCandidateById) {
      this.setState({
        loadingCV: false,
        cv: {
          cv: result.data.getCandidateById.CV,
          formattedCV: result.data.getCandidateById.formattedCV,
        },
        errors: [],
      });
    } else {
      this.setState({
        loadingCV: false,
        cv: {},
        errors: ['Unkown server error'],
      });
    }
  }

  render() {
    const { searchTerm, onCandidateClick, onRecommendationResult } = this.props;

    // eslint-disable-next-line no-unused-vars
    const { loadingCV, cv, errors } = this.state;

    if (errors && errors.length > 0) {
      return (
        <div className={s.candidateRecommendationsContainer}>
          {errors.length > 0 && <Error errors={errors} />}
        </div>
      );
    }

    return (
      <div className={s.candidateRecommendationsContainer}>
        {cv && (
          <CandidateRecommendationsPage
            referenceCV={cv}
            onCandidateClick={onCandidateClick}
            onRecommendationResult={onRecommendationResult}
            searchTerm={searchTerm}
          />
        )}
      </div>
    );
  }
}

export default compose(
  AuthenticationWrapper,
  withRouter,
  withStyles(s),
)(RecommendationsById);
