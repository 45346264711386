import React from 'react';
import PropTypes from 'prop-types';
import { Container } from 'react-bootstrap';
import withStyles from 'isomorphic-style-loader/lib/withStyles';

import baseStyles from '../../styles/app.scss';
import s from './Layout.scss';
import TopNav from '../TopNav';
import Sidebar from '../Sidebar';

/*
const getCategoriesQuery = gql`
  query getCategories {
    getCategories {
      categories
    }
  }
`;
*/

class Layout extends React.Component {
  static contextTypes = {
    client: PropTypes.object.isRequired,
  };

  static propTypes = {
    children: PropTypes.node.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      loadingCategories: false,
      categories: [],
    };

    // this.loadCategories = this.loadCategories.bind(this);
  }

  /*
  componentDidMount() {
    this.loadCategories();
  }

  async loadCategories() {
    try {
      this.setState({ loadingCategories: true });

      const result = await this.context.client.query({
        query: getCategoriesQuery,
        fetchPolicy: 'network-only',
      });

      if (result && result.data && result.data.getCategories) {
        if (result.data.getCategories.errors) {
          this.setState({
            loadingCategories: false,
          });
        } else {
          this.setState({
            loadingCategories: false,
            categories: result.data.getCategories.categories || [],
          });
        }
      }
    } catch (e) {
      this.setState({
        loadingCategories: false,
      });
    }
  }
  */

  render() {
    const { loadingCategories, categories } = this.state;

    return (
      <Container fluid style={{ height: '100%' }}>
        <TopNav categories={loadingCategories ? [] : categories || []} />
        <div>
          <div className={`sidebar-collapse ${s.leftLayoutColumn}`}>
            <Sidebar categories={loadingCategories ? [] : categories || []} />
          </div>
          <div className={`sidebar-collapse ${s.rightLayoutColumn}`}>
            {this.props.children}
          </div>
        </div>
      </Container>
    );
  }
}

export default withStyles(baseStyles, s)(Layout);
