import React from 'react';
import PropTypes from 'prop-types';
import { Navbar, Nav } from 'react-bootstrap';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import withStyles from 'isomorphic-style-loader/lib/withStyles';

import WorkFieldSearchField from '../WorkFieldSearchField';
import s from './TopNav.scss';
import danubeFish from '../../assets/fishy_blue_horizontal.png';

const TopNav = () => (
  <Navbar
    collapseOnSelect
    expand="lg"
    className={`recruiting-top-nav ${s.topNavContainer}`}
  >
    <Navbar.Brand className={s.logoContainer}>
      <Link className={s.logoLink} to="/">
        <img className={s.logoIcon} src={danubeFish} alt="" />
        <div className={s.logoName}>Recruiting Demo</div>
      </Link>
    </Navbar.Brand>
    <Nav className={`sm-auto m-auto ${s.searchFieldContainer}`}>
      <WorkFieldSearchField />
    </Nav>
  </Navbar>
);

TopNav.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
};

export default withRouter(withStyles(s)(TopNav));
