import Login from './login';
import LandingPage from './landingPage';
import CandidateSearchPage from './candidateSearchPage';
// import AnalyticsDashboard from './analyticsDashboard';

const routes = [
  {
    path: '/',
    component: LandingPage,
    exact: true,
  },
  {
    path: '/login',
    component: Login,
    exact: true,
  },
  {
    path: '/search',
    component: CandidateSearchPage,
    exact: true,
  },
  /*
  {
    path: '/recommendations/search/:searchTerm',
    component: RecommendationsBySearchTerm,
    exact: true,
  },
  {
    path: '/recommendations/candidate/:id',
    component: RecommendationsByCandidateId,
    exact: true,
  },
  {
    path: '/dashboard',
    component: AnalyticsDashboard,
    exact: true,
  },
  */
];

export default routes;
