import { SET_VISUALIZATION_SETTINGS } from '../constants';

const initialState = {
  includeAge: false,
};

export default function visualizationSettings(
  state = { ...initialState },
  action,
) {
  switch (action.type) {
    case SET_VISUALIZATION_SETTINGS: {
      const { newVisualizationSettings } = action.payload;

      const newState = {
        ...state,
        ...newVisualizationSettings,
      };

      return newState;
    }
    default:
      return state;
  }
}
