/* eslint-disable react/sort-comp */
import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import FaSearch from 'react-icons/lib/fa/search';

import s from './WorkFieldSearchField.scss';
import AuthenticationWrapper from '../Auth/AuthenticationWrapper';
import AutoCompleteField from '../WorkFieldSearchField/AutoCompleteField';

class WorkFieldSearchField extends React.Component {
  static contextTypes = {
    client: PropTypes.object.isRequired,
  };

  static propTypes = {
    location: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    history: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  };

  constructor(props) {
    super(props);

    let searchTerm = '';

    if (props.location.search != null) {
      if (props.location.search.startsWith('?term=')) {
        searchTerm = decodeURIComponent(
          props.location.search.substring('?term='.length),
        );
      } else if (props.location.search.startsWith('?candidate=')) {
        const searchTermIndex = props.location.search.indexOf('&term=');

        if (searchTermIndex !== -1) {
          searchTerm = decodeURIComponent(
            props.location.search.substring(searchTermIndex + '?term='.length),
          );
        }
      }
    }

    this.state = {
      searchTerm,
    };

    this.setAutoCompleteValue = this.setAutoCompleteValue.bind(this);
    this.onSearch = this.onSearch.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.location.search !== this.props.location.search &&
      this.props.location.search != null
    ) {
      if (this.props.location.search.startsWith('?term=')) {
        const searchTermUrl = decodeURIComponent(
          this.props.location.search.substring('?term='.length),
        );

        if (this.state.searchTerm !== searchTermUrl) {
          // eslint-disable-next-line react/no-did-update-set-state
          this.setState({ searchTerm: searchTermUrl });
        }
      } else if (this.props.location.search.startsWith('?candidate=')) {
        const searchTermIndex = this.props.location.search.indexOf('&term=');

        if (searchTermIndex !== -1) {
          const searchTermUrl = decodeURIComponent(
            this.props.location.search.substring(
              searchTermIndex + '?term='.length,
            ),
          );

          // eslint-disable-next-line react/no-did-update-set-state
          this.setState({ searchTerm: searchTermUrl });
        } else {
          // eslint-disable-next-line react/no-did-update-set-state
          this.setState({ searchTerm: '' });
        }
      }
    }
  }

  setAutoCompleteValue(value) {
    this.setState({ searchTerm: value });
  }

  onSearch() {
    const { history } = this.props;
    const { searchTerm } = this.state;

    if (searchTerm && searchTerm.trim !== '') {
      history.push(`/search?term=${encodeURIComponent(searchTerm)}`);
    }
  }

  render() {
    const { searchTerm } = this.state;

    return (
      <div className={s.searchField}>
        <AutoCompleteField
          autoCompleteValue={searchTerm}
          setAutoCompleteValue={this.setAutoCompleteValue}
        />
        <div>
          <button className={s.searchButton} onClick={this.onSearch}>
            <FaSearch style={{ position: 'relative', top: '-1px' }} />
          </button>
        </div>
      </div>
    );
  }
}

export default AuthenticationWrapper(withStyles(s)(WorkFieldSearchField));
