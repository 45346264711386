import React from 'react';
import { withRouter } from 'react-router';
import withStyles from 'isomorphic-style-loader/lib/withStyles';

import s from './Sidebar.scss';
import danubeFish from '../../assets/fishy_blue_horizontal.png';
import RecommendationResultsVisualization from '../RecommendationResultsVisualization';

const Sidebar = () => (
  <div className={s.sidebar}>
    <RecommendationResultsVisualization />
    <hr />
    <span className={s.poweredBy}>
      powered by&nbsp;
      <a
        href="https://danube.ai"
        title="danube.ai"
        target="_blank"
        rel="noopener noreferrer"
      >
        danube.ai
      </a>
      <img src={danubeFish} alt="danube.ai" />
    </span>
  </div>
);

export default withRouter(withStyles(s)(Sidebar));
