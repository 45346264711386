var escape = require("../../../node_modules/css-loader/lib/url/escape.js");
exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".FL5lV{display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center;-ms-flex-pack:center;justify-content:center;height:100%}._2o325{min-height:800px;padding:15px 45px;border-radius:8px;background:url(" + escape(require("./form-background.jpg")) + ");background-size:cover;z-index:0}._2o325,._2o325 ._3iZyi{display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center;-ms-flex-pack:center;justify-content:center;-ms-flex-direction:column;flex-direction:column;position:relative}._2o325 ._3iZyi{z-index:2}._2o325 h1{margin:15px 0;color:#fff;font-size:2em;text-align:center}._2o325 img{width:120px;margin-bottom:15px}._2o325 ._1KVa0{position:relative;margin:0;padding:15px 35px;background:#fff;z-index:2}._2o325 ._1KVa0 label{color:#4a4a4a}._2o325 ._1KVa0 input{width:100%;font-size:1.2em;background:#fff;border:none;border-bottom:1px solid #115699}._2o325 ._1KVa0:first-child{padding-top:35px;border-top-left-radius:8px;border-top-right-radius:8px}._2o325 ._1KVa0:last-child{padding-bottom:35px;border-bottom-left-radius:8px;border-bottom-right-radius:8px}._2o325 .N9Vz4{margin-top:2px;color:#ff5722}._2o325 .hIM8N{margin-top:35px;padding:10px 45px;border:none;border-radius:12px;background:hsla(0,0%,100%,.8)}._2o325 .hIM8N:active,._2o325 .hIM8N:focus,._2o325 .hIM8N:hover{background:#fff}._2o325:after{position:absolute;top:0;left:0;width:100%;height:100%;border-radius:8px;background-color:rgba(0,0,0,.5);content:\" \";z-index:1}", ""]);

// exports
exports.locals = {
	"teal": "#00dcb2",
	"grey": "#b4b4b4",
	"container": "FL5lV",
	"form": "_2o325",
	"formContent": "_3iZyi",
	"formGroup": "_1KVa0",
	"formError": "N9Vz4",
	"submitButton": "hIM8N"
};