exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3X-DF{width:386px;height:100%;min-height:calc(100vh - 140px);padding:15px;color:#333;background-color:#fff;border-radius:5px;border:1px solid #e9eaed}@media (max-width:1200px){._3X-DF{width:100%;height:auto;min-height:0;padding:0;background:none;border:none}}._3X-DF a{padding-left:0;padding-right:0}._3X-DF a,._3X-DF a:active,._3X-DF a:focus,._3X-DF a:hover{color:#333}._3X-DF hr{border-top:1px solid #333}@media (max-width:1200px){._3X-DF hr{display:none}}._3X-DF .h8IWR{display:block;width:100%;font-size:.8em;text-align:center}@media (max-width:1200px){._3X-DF .h8IWR{display:none}}._3X-DF .h8IWR a{color:#0db5ff;font-family:Gotham Rounded A,Gotham Rounded B,HelveticaNeue-light,sans-serif}._3X-DF .h8IWR img{display:block;width:40px;margin:5px auto}", ""]);

// exports
exports.locals = {
	"teal": "#00dcb2",
	"grey": "#b4b4b4",
	"sidebar": "_3X-DF",
	"poweredBy": "h8IWR"
};