exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2nGxz{margin:10px 0 15px;padding:15px;border-left:0;border-right:0;background-color:#fff}._22bhw{font-weight:700}._9q86S{background-color:#0db5ff}._9q86S,.RHAB6{display:inline-block;padding:5px 7px;border-radius:6px;margin:2px;font-size:14px}.RHAB6{background-color:#92deff}", ""]);

// exports
exports.locals = {
	"teal": "#00dcb2",
	"grey": "#b4b4b4",
	"tagsMap": "_2nGxz",
	"label": "_22bhw",
	"danubeWrapper": "_9q86S",
	"danubeWrapperLight": "RHAB6"
};