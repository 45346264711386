exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2epw8{border:1px solid #e9eaed;height:100px}._2epw8 ._1Wiwg{-ms-flex:1 1;flex:1 1;display:-ms-flexbox;display:flex;-ms-flex-direction:row;flex-direction:row;-ms-flex-pack:center;justify-content:center;width:100%;color:#333}._2epw8 ._1rSDE{height:100%;border:none;outline:none;padding:0}._2epw8 ._1rSDE .JDLJa{display:-ms-flexbox;display:flex;-ms-flex-direction:row;flex-direction:row;-ms-flex-align:center;align-items:center;width:auto;height:100%;text-decoration:none}._2epw8 ._1rSDE .inFSd{width:70px;height:auto;margin-left:5px;margin-right:15px;vertical-align:middle}._2epw8 ._1rSDE ._3h3dp{display:inline-block;font-family:Gotham Rounded A,Gotham Rounded B,HelveticaNeue-light,sans-serif;font-style:normal;font-weight:700;font-size:35px;color:#0db5ff;vertical-align:middle}@media (max-width:1200px){._2epw8 ._1rSDE ._3h3dp{display:none}}", ""]);

// exports
exports.locals = {
	"teal": "#00dcb2",
	"grey": "#b4b4b4",
	"topNavContainer": "_2epw8",
	"searchFieldContainer": "_1Wiwg",
	"logoContainer": "_1rSDE",
	"logoLink": "JDLJa",
	"logoIcon": "inFSd",
	"logoName": "_3h3dp"
};